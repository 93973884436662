import { Config, HistoricoPadrao, Sequencias, TipoLancamentoDataApi, TiposLancamentosData, TipoVariavel } from '../../../types/tipoLancamentoApiTypes'
import api from '../../axiosInstance'

const buscarSequencias = async (tipoNaturezaContabil?: string): Promise<Sequencias[]> => {
  const URL_BUSCAR_SEQUENCIAS = tipoNaturezaContabil
    ? `/ParametrosContabeis/Sequencias?tipoNaturezaContabil=${tipoNaturezaContabil}`
    : `/ParametrosContabeis/Sequencias`
  const response = await api.conectarGet<Sequencias[]>(URL_BUSCAR_SEQUENCIAS)
  return response.data
}

const buscarConfigAtiva = async (): Promise<Config> => {
  const URL_BUSCAR_CONFIG_ATIVO = `/ParametrosContabeis/Ativo`
  const response = await api.conectarGet<Config>(URL_BUSCAR_CONFIG_ATIVO, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY
    }
  })
  return response.data
}

const buscarTiposLancamentos = async (): Promise<TiposLancamentosData[]> => {
  const URL_BUSCAR_TODOS_LANCAMENTOS = `/TiposLancamentos`
  const response = await api.conectarGet<TiposLancamentosData[]>(URL_BUSCAR_TODOS_LANCAMENTOS, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY
    }
  })
  return response.data
}

const inserirTipoLancamento = async (body: TipoLancamentoDataApi): Promise<TiposLancamentosData> => {
  const response = await api.conectarPost<TiposLancamentosData>(`/TiposLancamentos`, body)
  return response.data
}

const alterarTipoLancamento = async (body: TipoLancamentoDataApi, tipoLancamentoId?: number): Promise<void> => {
  await api.conectarPut<void>(`/TiposLancamentos/${tipoLancamentoId}`, body)
}

const validarHistoricoPadrao = async (body: HistoricoPadrao, sequenciaId?: number): Promise<void> => {
  await api.conectarPost<void>(`/ParametrosContabeis/Sequencia/${sequenciaId}/HistoricoPadrao/Validar`, body)
}

const salvarHistoricoPadrao = async (body: HistoricoPadrao | undefined, tipoLancamentoId?: number): Promise<void> => {
  await api.conectarPost<void>(`/TiposLancamentos/${tipoLancamentoId}/HistoricoPadrao`, body)
}

const alterarHistoricoPadrao = async (body: HistoricoPadrao | undefined, tipoLancamentoId?: number): Promise<void> => {
  await api.conectarPut<void>(`/TiposLancamentos/${tipoLancamentoId}/HistoricoPadrao`, body)
}

const obterHistoricoPadraoTipoLancamento = async (tipoLancamentoId?: number): Promise<HistoricoPadrao> => {
  const URL_OBTER_VARIAVEIS_HISTORICO_PADRAO = `/TiposLancamentos/${tipoLancamentoId}/HistoricoPadrao`
  const response = await api.conectarGet<HistoricoPadrao>(URL_OBTER_VARIAVEIS_HISTORICO_PADRAO, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY
    }
  })
  return response.data
}

const obterHistoricoPadraoSequencia = async (sequenciaId: number): Promise<HistoricoPadrao> => {
  const URL_OBTER_VARIAVEIS_HISTORICO_PADRAO = `ParametrosContabeis/Sequencia/${sequenciaId}`
  const response = await api.conectarGet<HistoricoPadrao>(URL_OBTER_VARIAVEIS_HISTORICO_PADRAO, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY
    }
  })
  return response.data
}

const obterTiposVariaveisHistoricoPadrao = async (): Promise<TipoVariavel[]> => {
  const URL_OBTER_TIPOS_VARIAVEIS_HISTORICO_PADRAO = `TiposLancamentos/Variaveis`
  const response = await api.conectarGet<TipoVariavel[]>(URL_OBTER_TIPOS_VARIAVEIS_HISTORICO_PADRAO, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY
    }
  })
  return response.data
}

export default {
  buscarSequencias,
  buscarConfigAtiva,
  buscarTiposLancamentos,
  inserirTipoLancamento,
  alterarTipoLancamento,
  validarHistoricoPadrao,
  salvarHistoricoPadrao,
  alterarHistoricoPadrao,
  obterHistoricoPadraoTipoLancamento,
  obterHistoricoPadraoSequencia,
  obterTiposVariaveisHistoricoPadrao
}
