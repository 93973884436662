import { useState } from 'react'
import { useConciliacaoContext } from '../../../../contexts/conciliacao-context'
import { ItemDetalheControleComissaoPedidoStyles } from './ItemDetalheControleComissaoPedido.styles'

const classificacaoFromNum = (classificacao: number | null): string => {
  if (classificacao === 2) return 'Em Andamento'
  if (classificacao === 1) return 'Cloncluido'
  return 'Pendente'
}

export function itemDetalheControleComissaoPedidoIO() {
  const [historicoAberto, setPedidoAberto] = useState(false)
  const { isCheckListSelecionado } = useConciliacaoContext()

  return {
    styles: ItemDetalheControleComissaoPedidoStyles,
    historicoAberto,
    handleAbrirHistoricoToggle: () => setPedidoAberto(!historicoAberto),
    isCheckListSelecionado,
    classificacaoFromNum
  }
}

export type ItemDetalheControleComissaoPedidoIO = ReturnType<typeof itemDetalheControleComissaoPedidoIO>
