import { Login, LoginResponse } from '../../../types/loginApiTypes'
import instance from '../../axiosInstance'

const refreshToken = async (): Promise<LoginResponse> => {
  const URL_REFRESH_TOKEN = '/Autenticacao/RefreshToken'
  const response = await instance.conectarGet<LoginResponse>(URL_REFRESH_TOKEN, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY
    }
  })
  return response.data
}

const autenticacaoLogin = async (body: Login): Promise<string> => {
  const response = await instance.conectarPost<string>('/Autenticacao/Login', body)
  return response.data
}

const logout = async (): Promise<void> => {
  const response = await instance.conectarDelete<void>('/Autenticacao/Logout')
  return response.data
}

const obterAcessosMenu = async (): Promise<string[]> => {
  const response = await instance.conectarGet<string[]>('/Autenticacao/Perfis')
  return response.data
}

export default { refreshToken, autenticacaoLogin, logout, obterAcessosMenu }
