import { TableCell, TableRow, Checkbox, IconButton } from '@material-ui/core'
import { IOProps } from 'react-compose-io'
import { formateCurrency, formateDate, porcentagemNaoMultiplicada } from '../../../../utils/stringUtils'
import { ItemDetalheControleComissaoPedidoIO } from './ItemDetalheControleComissaoPedido.IO'
import { corClassificacaoLetra, corClassificacaoChip, customTheme } from '../../../../styles/customTheme'
import { ChipClassificacaoPerda } from './../../../../components/ChipClassificacaoPerda/ChipClassificacaoPerda'
import { DetalheControleComissaoPedidoResponse } from '../../../../types/controleComissaoTypes'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { HistoricoControleComissaoPedido } from '../HistoricoControleComissaoPedido'

export type ItemDetalheControleComissaoPedidoProps = {
  item: DetalheControleComissaoPedidoResponse
  checkListSelecionados: string[]
  handleClickCheckList: (key: string) => void
  chave: string
}

export function ItemDetalheControleComissaoPedidoView({
  _io,
  item,
  handleClickCheckList,
  chave
}: IOProps<ItemDetalheControleComissaoPedidoIO, ItemDetalheControleComissaoPedidoProps>) {
  const { TipografiaInline } = _io.styles
  return (
    <>
      <TableRow>
        <TableCell align="center" size="small">
          <IconButton size="small" onClick={_io.handleAbrirHistoricoToggle}>
            {_io.historicoAberto ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" size="small">
          <Checkbox checked={_io.isCheckListSelecionado(chave)} onChange={() => handleClickCheckList(chave)} />
        </TableCell>
        <TableCell align="center" size="small">
          {item.mercadoriaId}
        </TableCell>
        <TableCell align="center" size="small">
          {item.parcela}
        </TableCell>
        <TableCell align="center" size="small">
          {formateDate(item.dataProcessamentoCiclo)}
        </TableCell>
        <TableCell align="center" size="small">
          {item.cicloId}
        </TableCell>
        <TableCell align="center" size="small">
          {formateCurrency(item.valorRecebido)}
        </TableCell>
        <TableCell align="center" size="small">
          {formateCurrency(item.comissaoCobrada)}
          {'   '}
          <TipografiaInline variant="inherit" colorText={customTheme.colors.vermelho05}>
            {porcentagemNaoMultiplicada(item.percentualCobrado)}
          </TipografiaInline>
        </TableCell>
        <TableCell align="center" size="small">
          {formateCurrency(item.comissaoDevida)}
          {'   '}
          <TipografiaInline variant="inherit" colorText={customTheme.colors.azul06}>
            {porcentagemNaoMultiplicada(item.percentualDevido)}
          </TipografiaInline>
        </TableCell>
        <TableCell align="center" size="small">
          {formateCurrency(item.diferenca)}
        </TableCell>
        <TableCell align="center" size="small">
          <ChipClassificacaoPerda
            label={_io.classificacaoFromNum(item.classificacaoStatus)}
            bgcolor={corClassificacaoChip.get(_io.classificacaoFromNum(item.classificacaoStatus))}
            fontcolor={corClassificacaoLetra.get(_io.classificacaoFromNum(item.classificacaoStatus))}
          />
        </TableCell>
      </TableRow>
      <HistoricoControleComissaoPedido items={item.historicoPedidoComissao} historicoAberto={_io.historicoAberto} />
    </>
  )
}
